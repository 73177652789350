import e from 'express';

const home = {
  img: '/svg/icon/classHome-gray.svg',
  imgActive: '/svg/icon/classHome-purple.svg',
  title: '클래스홈',
  path: `/lms/dashboard`,
};
const takeClass = {
  img: '/svg/icon/manageClass-gray.svg',
  imgActive: '/svg/icon/manageClass-purple.svg',
  title: '수업하기',
  path: `/lms/take-class`,
};
const studentMgmt = {
  img: '/svg/icon/manageStudent-gray.svg',
  imgActive: '/svg/icon/manageStudent-purple.svg',
  title: '학생관리',
  path: `/lms/signup-management`,
};
const recitationTodo = {
  img: '/svg/icon/note-gray.svg',
  imgActive: '/svg/icon/note-purple.svg',
  title: '인증제 관리',
  path: `/lms/todo-management/recitation`,
};
const JebspleMgmt = {
  img: '/svg/icon/todo-gray.svg',
  imgActive: '/svg/icon/todo-purple.svg',
  title: '젭스플 관리',
  path: `/lms/todo-management/jebspl`,
};
const JebsonMgmt = {
  img: '/svg/icon/todo-gray.svg',
  imgActive: '/svg/icon/todo-purple.svg',
  title: '젭스온 관리',
  path: `/lms/todo-management/jebson`,
};
const classSetting = {
  img: '/svg/icon/plus-gray.svg',
  imgActive: '/svg/icon/plus-purple.svg',
  title: '클래스설정',
  path: `/lms/class-setting`,
};
const noticeMgmt = {
  img: '/svg/footer/jebtalk.svg',
  title: 'TALK',
  path: `/lms/notice-management`,
};
export const NavigationList = (classId: string, subType: string) => {
  if (subType === 'home') {
    return [home, takeClass, recitationTodo, JebsonMgmt, studentMgmt];
  } else if (subType === 'church') {
    return [home, takeClass, JebspleMgmt, studentMgmt];
  } else {
    return [home, takeClass, studentMgmt];
  }
};
