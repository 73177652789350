import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import HFbutton from '../common/input/HFbutton';
import ChannelService from '../../api/ChannelService';
import { PopupType } from '../../constants/popupData';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';

const filteredPopupData = (popupList: PopupType[]): PopupType => {
  if (popupList.length === 0) {
    throw new Error('팝업 데이터가 없습니다.');
  }

  const defaultPopupLength = popupList.filter(
    (popup) => popup.endDate === undefined
  ).length;
  if (defaultPopupLength > 1) {
    throw new Error('팝업의 디폴트 데이터는 최대 하나만 가능 합니다.');
  }

  // endDate가 지나지 않은 것만 필터링
  const validPopups = popupList.filter(
    (popup) => !popup.endDate || dayjs().isBefore(popup.endDate)
  );

  // endDate가 없는 팝업 데이터 찾기
  const defaultPopup = validPopups.find((popup) => !popup.endDate);

  // endDate가 있는 것들 중 가장 이른 날짜 찾기
  const earliestPopup = validPopups
    .filter((popup) => popup.endDate)
    .sort((a, b) => (a.endDate!.isBefore(b.endDate!) ? -1 : 1))[0];

  // endDate가 있는 것이 없을 경우 기본 팝업 반환, 있으면 가장 이른 것 반환
  return earliestPopup || defaultPopup;
};

interface ModalPopupProps {
  list: PopupType[];
}
const ModalPopup = ({ list }: ModalPopupProps) => {
  const POPUP_BLOCK = 'popupBlockDay';
  const dispatch = useAppDispatch();
  const [block, setBlock] = React.useState(false);
  const { img, link, type, size } = filteredPopupData(list);

  useEffect(() => {
    ChannelService.hideChannelButton();
    const dayBlock = localStorage.getItem(POPUP_BLOCK);
    const day = dayjs(dayBlock, 'YYYY. M. D.');

    // 하루간 보지 않기를 누른 날짜가 오늘이라면 팝업을 닫는다.
    if (day && day.isSame(dayjs(), 'day')) {
      closeHandler();
    }

    return () => {
      ChannelService.showChannelButton();
    };
  }, []);

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const dayBlockHandler = () => {
    localStorage.setItem(POPUP_BLOCK, new Date().toLocaleDateString());
    closeHandler();
  };

  const clickHandler = () => {
    if (!link) return;
    window.open(link, type);
  };
  return (
    <div
      className={`flex flex-col gap-1 p-1 max-w-[720px] w-full bg-white  rounded`}
    >
      {!block && (
        <Skeleton.Image active style={{ width: '100%', height: '300px' }} />
      )}
      <img
        className={`w-full ${
          link === undefined ? '' : 'cursor-pointer'
        } object-cover ${block ? '' : 'hidden'}`}
        src={img}
        onClick={clickHandler}
        onLoad={() => setBlock(true)}
      />
      <div className="flex gap-3 p-2">
        <HFbutton type="Outlined" onClick={dayBlockHandler}>
          하루간 보지 않기
        </HFbutton>
        <HFbutton onClick={closeHandler}>닫기</HFbutton>
      </div>
    </div>
  );
};

export default ModalPopup;
