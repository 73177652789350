import e from 'express';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { openAlert } from '../store/features/modalSlice';
import { useState } from 'react';
import React from 'react';
import { NavigationList } from './lmsBottomNavigationItems';
import { selectLmsLists } from '../store/features/lmsSlice';
import { courseIdDetermination } from '../api/utils/lms/ExtendSetBtnLink';

interface NavigationType {
  img: string;
  imgActive: string;
  title: string;
  path: string;
}
const LmsBottomNavigation = ({ classId }: { classId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lmsLists = useAppSelector(selectLmsLists);

  const list = React.useMemo(() => {
    return NavigationList(classId, courseIdDetermination(classId, lmsLists));
  }, [classId, lmsLists]);

  const [path, setPath] = useState(list[0].path);

  const handleNavigation = (path: string, classId: string) => {
    const fullPath = path + '/' + classId;
    if (location.pathname === path) return;

    if (path === '/lms/class-setting') {
      dispatch(openAlert({ message: '준비 중인 기능입니다.' }));
      return;
    }

    setPath(path);
    navigate(fullPath);
  };

  return (
    <div
      className={`fixed grid grid-cols-${list.length} items-center left-0 z-10 w-full h-20 bg-white shadow-card`}
    >
      {list.map((navigation, idx) => (
        // <Link to={navigation.path + "/" + classId} key={idx}>
        <div
          className="flex flex-col items-center"
          onClick={() => handleNavigation(navigation.path, classId)}
          key={idx}
        >
          <img
            src={
              process.env.PUBLIC_URL + path === navigation.path
                ? navigation.imgActive
                : navigation.img
            }
            width={'40px'}
            height={'40px'}
            alt={navigation.title}
          />
          <div
            className={`text-sm font-normal ${
              path === navigation.path ? 'text-purple5' : 'text-gray7'
            }`}
          >
            {navigation.title}
          </div>
        </div>
        // </Link>
      ))}
    </div>
  );
};

export default LmsBottomNavigation;
