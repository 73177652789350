import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

export const onResponse = (res: AxiosResponse) => {
  const { method, url } = res.config;
  return res;
};

let isAlertShown = false;

export const onResError = (error: AxiosError | Error) => {
  if (axios.isAxiosError(error)) {
    const { method, url } = error.config as InternalAxiosRequestConfig;
    if (error.response) {
      const { statusCode, message } = error.response.data;
      if (isAlertShown) return;
      isAlertShown = true;
      switch (statusCode) {
        case 401:
          if (url === '/v1/auth/sign-in') {
            return Promise.reject(error);
          }

          alert('로그인이 필요합니다.');
          if (!localStorage.getItem('redirect')) {
            if (window.location.href.split('/').pop() !== 'signin')
              localStorage.setItem('redirect', window.location.href);
          }
          window.location.href = '/signin';

          setTimeout(() => {
            isAlertShown = false;
          }, 1000);

          return error.response;
        case 403:
          alert('접근 권한이 없습니다.');
          window.location.href = '/';
          return error.response;
        default:
          return Promise.reject(error);
      }
    }
  } else {
    console.log(`🚨 [API] | Error ${error.message}`);
  }

  return Promise.reject(error);
};
