import React from 'react';
import {
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import TextWinds from '../../../components/common/TextWinds';
import StudentConroller from './StudentController';
import Buttons from '../../../components/common/Buttons2';
import { TrashIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { UserMinusIcon, UserPlusIcon } from '@heroicons/react/20/solid';

interface StudentStateControlProps {
  status: studentStatusType;
  studentList: studentType[];
  reloadTable: () => void;
  controller: StudentConroller;
}
const StudentStateControl = ({
  status,
  studentList,
  controller,
}: StudentStateControlProps) => {
  return (
    <div>
      <div className="flex justify-end gap-x-[22px] mx-5 py-2 xl:mx-0">
        <div className="flex items-center gap-x-3">
          <TextWinds type="content_body4" color="gray7" className="w-[68px]">
            {studentList.length}명 선택됨
          </TextWinds>
        </div>
        <div className="flex gap-x-2">
          {status === studentStatusType.ACTIVE && (
            <Buttons
              variant="outlined"
              color="red5"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => controller.assignHandler(studentList)}
            >
              <UserGroupIcon className="h-5 w-5 text-red-500 mr-2" />
              선택 반 배정
            </Buttons>
          )}
          {status === studentStatusType.ACTIVE && (
            <Buttons
              variant="filled"
              color="orange4"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => controller.stopHandler(studentList)}
            >
              <UserMinusIcon className="h-5 w-5 text-white mr-2" />
              선택 정지
            </Buttons>
          )}
          {(status === studentStatusType.INACTIVE ||
            status === studentStatusType.PENDING) && (
            <Buttons
              variant="filled"
              color="purple5"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => controller.registHandler(studentList)}
            >
              <UserPlusIcon className="h-5 w-5 text-white mr-2" />
              선택 등록
            </Buttons>
          )}
          {(status === studentStatusType.INACTIVE ||
            status === studentStatusType.PENDING) && (
            <Buttons
              variant="outlined"
              color="red5"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => controller.deleteHandler(studentList)}
            >
              <TrashIcon className="h-5 w-5 text-red-500 mr-2" />
              선택 삭제
            </Buttons>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentStateControl;
