import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../store/hook';
import {
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import StudentStateControl from './StudentStateControl';
import { Pagination, Table } from 'antd';
import columns from './columns';
import { useParams, useSearchParams } from 'react-router-dom';
import { PaginationProps } from '../../../constants/types';
import StudentConroller from './StudentController';

interface StudentListProps {
  status: studentStatusType;
  data: studentType[];
  pageInfo: PaginationProps;
  isLoading: boolean;
  reloadStudentList: () => void;
}
const StudentList = ({
  status,
  data,
  pageInfo,
  isLoading,
  reloadStudentList,
}: StudentListProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { classId } = useParams<{ classId: string }>();
  const [selectedStudents, setSelectedStudents] = useState<studentType[]>([]);

  const controller = useMemo(() => {
    return new StudentConroller(dispatch, classId, reloadStudentList);
  }, [classId, reloadStudentList]);

  useEffect(() => {
    setSelectedStudents([]);
  }, [data]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedStudents(
        data.filter((item) => selectedRowKeys.includes(item.id))
      );
    },
    selectedRowKeys: selectedStudents.map((item) => item.id),
  };

  return (
    <div>
      {/* 상태별 컨트롤 버튼 */}
      <StudentStateControl
        status={status}
        reloadTable={reloadStudentList}
        studentList={selectedStudents}
        controller={controller}
      />

      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns(pageInfo, status, controller)}
        dataSource={data}
        rowKey={(record) => record.id}
        scroll={{ x: 'max-content' }}
        pagination={false}
        size="small"
        loading={isLoading}
      />

      <Pagination
        current={pageInfo.page}
        total={pageInfo.total}
        pageSize={pageInfo.pageSize}
        showSizeChanger
        onChange={(page, pageSize) => {
          searchParams.set('page', page.toString());
          searchParams.set('pageSize', pageSize.toString());
          setSearchParams(searchParams);
        }}
        className="mt-5"
      />
    </div>
  );
};

export default StudentList;
