import dayjs, { Dayjs } from 'dayjs';

export type PopupType = {
  img: string;
  size: number;
  link?: string;
  type?: '_blank';
  endDate?: Dayjs;
};

export const popupList: PopupType[] = [
  {
    img: process.env.PUBLIC_URL + 'images/home/popup/20240716.jpg',
    link: 'https://jebs-notice.oopy.io/3445e325-9c88-40d5-a77f-a862a8e46af5',
    size: 480,
  },
  {
    img: process.env.PUBLIC_URL + 'images/home/popup/20240911.jpg',
    size: 720,
    endDate: dayjs('2024-09-20'),
  },
];
